.App,
body,
html {
    position: relative;
    width: 100%;
    height: 100%;
}
.firstBlock {
    position: relative;
    height: 100%;
    margin-top: 100vh;
    background-color: white;
}

.fullBlock {
    position: relative;
    height: 100%;
    background-color: white;
}
.video-background {
    background: #000;
    position: fixed;
    color: #fff;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}

.video-body {
    z-index: 10;
    display: grid;
    height: 100vh;
    margin: 0;
    place-items: center center;
}
.page-component {
    height: 100vh;
}
